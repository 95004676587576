import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import store from './store/index' 
import 'element-ui/lib/theme-chalk/index.css';
// main.js 或者其他的 Vue 插件配置文件
import VueLazyload from 'vue-lazyload'
Vue.prototype.$storage = window.localStorage
Vue.use(VueLazyload, {
  // 设置懒加载时的占位图
  loading: require('@/assets/cthumb.gif'),
  // 设置图片加载失败时的占位图
  error:require('@/assets/cthumb.gif'),
  preLoad: 1.3, // 预加载高度的比例
  attempt: 3
})
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
