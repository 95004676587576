<template>
    <div class="navBar">
      <img :src="store.logo" alt="" class="logo">
      <!-- <div class="input">
        <el-input placeholder="検索" class="el-input" v-model="search" @keyup.enter.native="searchClick"></el-input>
        <img src="@/assets/search.png" alt="" @click="searchClick">
      </div> -->
      <div></div>
      <img :src="avatar" alt="" @error="imgError(avatar)" class="avatar" @click="logout">
      <div class="right-bell">
        <div>テーブルナンバー：<span class="spana">{{ tableNumber.title }}</span></div>
        <div @click="callServer" :class="{ button: !isCountingDown, 'button-countdown': isCountingDown }">
            <img src="@/assets/bell.png" alt="">
            スタッフ呼び出し<span v-if="isCountingDown">(</span>{{countdownText}}<span v-if="isCountingDown">)</span>
        </div>
        <img src="@/assets/er.png" alt="" class="er" @click="getQrcode">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'navBar',
    props: {
        tableNumber:{
            type:Object,
            default(){
                return {}
            }  
        },
        store:{
            type:Object,
            default(){
                return {}
            }  
        },
        img:{
            type:String,
            default:""
        },
    },
    data(){
        return{
            avatar:this.$store.state.userInfo.avatar?this.$store.state.userInfo.avatar:require('@/assets/avatar.gif'),
            search:"",
            isCountingDown: false,//倒计时状态
            countdownDuration: 10,//倒计时初始值
            remainingTime: 0,//当前倒计时剩余时间
        }
    },
    computed: {
        countdownText() {
            return this.isCountingDown ? `${this.remainingTime}s` : '';
        },
    },
    methods: {
        getQrcode(){
            this.$emit("getQrcode")
        },
        imgError(){
            this.avatar = require('@/assets/avatar.gif')
        },
        logout(){
            if(this.$store.state.userInfo.token){
                this.$router.push({
                    path: '/personal',
                    query: {
                        tid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
                        sid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
                    }
                });
            }else{
                this.$router.push({
                    path: '/login',
                    query: {
                        tid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
                        sid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
                    }
                });
            }
        },
        searchClick(){
            this.$emit("searchClick",this.search)
        },
        callServer(){
            if (!this.isCountingDown) {
                this.isCountingDown = true;
                this.remainingTime = this.countdownDuration;
                this.$emit("callServer")
                this.startCountdown();
            }
        },
        startCountdown() {
            const countdownInterval = setInterval(() => {
                if (this.remainingTime > 1) {
                this.remainingTime--;
                } else {
                clearInterval(countdownInterval);
                this.isCountingDown = false;
                this.remainingTime = 0;
                }
            }, 1000); // 每秒更新一次倒计时
        },      
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .navBar{
        position: fixed;
        top:0;
        z-index:9;
        width:calc(100% - 8.75rem);
        height:60px;
        padding:15px 10px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        .input{
            width: 40%;
            padding:0 18px 0 23px;
            background-color: #F7F5F5;
            text-align: left;
            border-radius: 20px;
            display:flex;
            justify-content: space-between;
            align-items: center;
            img{
                width: 20px;
            }
        }
    }
    ::v-deep .el-input__inner:hover{
        outline:0;
    }
    ::v-deep .el-input__inner {
      background-color: #F7F5F5 !important;
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      height:30px;
      line-height:30px;
      vertical-align: middle;
    }
    .right-bell{
        display: flex;
        align-items: center;
        font-size:14px;
        div:first-child{
            display: flex;
            align-items: center;
        }
        .button{
            background-color: #A21B26;
            color:#FFFFFF;
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding:7px 13px;
            margin-left:25px;
            margin-right:10px;
            img{
                margin-right:8px;
                width:15px;
            }
        }
        .button-countdown{
            background-color: #909399;
            color:#FFFFFF;
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding:10px 13px;
            margin-left:25px;
            margin-right:10px;
            span{
                color:#FFFFFF;
            }
            img{
                width:15px;
                margin-right:8px;
            } 
        }
        .spana{
            font-size:18px;
            color:#A21B26;
            border:1px solid #A21B26;
            border-radius: 1.875rem;
            padding:0 0.9375rem;
            height:30px;
            line-height:30px;
        }
    }
    .logo,.avatar{
        display:none
    }
    .er{
        display:block;
        width:36px;
    }
    @media screen and (max-width: 767px){
        .er{
            display:none
        }
        .navBar{
            width:100%;
            height:auto;
            padding-bottom:0;
            flex-wrap: wrap;
            background-color: #FFFFFF;
            .input{
                padding:0.25rem 0.625rem;
                width:75%;
                img{
                    width:0.8125rem;
                }
            }
            .right-bell{
                width:100%;
                font-size:0.75rem;
                margin-top:0.4375rem;
                justify-content: space-between;
                .button{
                    background-color: #A21B26;
                    color:#FFFFFF;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    padding:6px 13px;
                    margin-left:10px;
                    margin-right:0;
                    img{
                        margin-right:8px;
                    }
                }
                .button-countdown{
                    background-color: #909399;
                    color:#FFFFFF;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    padding:6px 13px;
                    margin-left:10px;
                    margin-right:10px;
                    img{
                        margin-right:8px;
                    } 
                }
                .spana{
                    font-size:12px;
                    color:#A21B26;
                    border:none;
                    padding:0;
                    height:30px;
                    line-height:30px;
                }
            }
        }
        .avatar{
            display:block;
            width:1.5rem;
            height:1.5rem;
            border-radius: 50%;
        }
        ::v-deep .el-input__inner::placeholder{
            font-size:0.75rem;
            color:rgba(200, 168, 121, 1)!important;
        }
        ::v-deep .el-input__inner{
            height:20px;
        }
        .logo{
            display:block;
            width:1.5rem;
            height:1.5rem;
            border-radius: 5px;
        }
    }
  
  </style>
  