<template>
    <!-- 个人中心 -->
    <div class="login-container" >
        <div class="login-box">
            <el-upload
            class="avatar-uploader"
            name="avatar"
            :http-request="handleFileUpload"
            :show-file-list="false"
            v-loading="true"
            >
                <div class="avatar-box">
                    <img :src="avatarpath"  alt="" @error="errorHandler(avatar)" class="avatar" >
                    <div class="name">交換</div>
                </div>    
            </el-upload>
            <div class="btn" @click="logoutaction">
                終了
                <img src="@/assets/tuichu.png" alt="">
            </div>
            <el-form :model="ruleForm"  ref="ruleForm" :label-position="top" class="demo-ruleForm">
                <el-form-item label="ユーザー名です" prop="username">
                    <el-input  v-model="ruleForm.username" autocomplete="off" placeholder="ユーザー名の入力をお願いします" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="パスワード" prop="password">
                    <el-input  v-model="ruleForm.password" type="password" autocomplete="off" placeholder="パスワードが記入されなければ変更されません"></el-input>
                </el-form-item>
                <el-form-item label="名前です" prop="name">
                    <el-input  v-model="ruleForm.name" autocomplete="off" placeholder="パスワードを再入力してください"></el-input>
                </el-form-item>
                <el-form-item label="アカウント" prop="mobile">
                    <el-input  v-model="ruleForm.mobile" autocomplete="off" placeholder="パスワードを再入力してください"></el-input>
                </el-form-item>
            </el-form>
            <el-button class="save" @click="save">
                保存＃ホゾン＃
            </el-button>
        </div>
        <el-button class="sign" @click="sign">
            注文しに行く
        </el-button>
    </div>
</template>
<script>
   import { getUserInfo,userInfo,uploadAvatar} from '@/api/server.js'
    export default {
        data() {
            return {
                ruleForm:{},
                disabled:true,
                avatar:'',
                avatarpath:this.$store.state.userInfo.avatar?this.$store.state.userInfo.avatar:require('@/assets/avatar.gif'),
            }
        },
        mounted(){
            this.getUserInfo()
        },
        methods:{
            errorHandler() {
                this.avatarpath = require('@/assets/avatar.gif')
                return true
            },
            async handleFileUpload(option){
                var file = new FormData()
                file.append('avatar',option.file)
                const { data } = await uploadAvatar(file)
                this.$nextTick(() => {
                    this.avatar = data.avatar
                    this.avatarpath = data.avatarpath
                })  
            },
            sign(){
                this.$store.commit('setUserInfo', {
                    userInfo:{}
                });
                this.$router.push({
                    path: '/login',
                    query: {
                        tid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
                        sid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
                    }
                });
            },
            async getUserInfo(){
                let res = await getUserInfo()
                if(res.code == 1){
                    this.ruleForm = res.data
                    this.avatar = res.data.avatar
                }else{
                    this.$router.push({
                        path: '/login',
                        query: {
                            tid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
                            sid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
                        }
                    });
                }
            },
            logoutaction(){
                this.$router.push({
                    path: '/',
                    query: {
                        tid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
                        sid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
                    }
                });
            },
            async save(){
                let params = {
                    ...this.ruleForm,
                    avatar:this.avatar?this.avatar:'',
                    avatarpath:undefined
                }
                let res = await userInfo(params)
                if(res.code == 1){
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.$store.commit('setUserInfo',{
                        userInfo:{
                            ...this.$store.state.userInfo,
                            avatar:this.avatarpath
                        }
                    })
                }
            },
            Toggle(e){
                if(e == 0){
                    this.login = false;
                    this.ruleForm = {}
                }else{
                    this.login = true;
                    this.ruleForm = {}
                }   
            }
        }
    }
</script>
<style lang="scss" scoped>
::v-deep.el-input.is-disabled .el-input__inner {
    border:none !important;
    background-color: rgba(248, 249, 251, 1) !important;
}
::v-deep.el-form-item{
    margin-bottom:0;
}
.sign,.sign:hover{
    background-color: rgba(200, 168, 121, 1);
    width:calc(100% - 4.375rem);
    left:2.1875rem;
    position: fixed;
    bottom:1rem;
}
.save,.save:hover{
    background-color: #A21B26;
    margin-top:1.25rem;
    width:100%;
}
.save,.save:hover,.sign,.sign:hover{
    color:#FFFFFF;
    border:none;
    border-radius: 1.875rem;
    font-size:18px;
}
.login-container{
    background: linear-gradient(180deg, rgba(241, 234, 216, 0.5) 0%, rgba(200, 168, 121, 0) 100%);
    height:100vh;
    padding-top:2.5rem;
    box-sizing: border-box;
}
.login-box{
    padding:1.25rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    width:calc(100% - 1.875rem);
    margin:0 auto;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 11px  rgba(212, 48, 48, 0.15);
    position: relative;
    display: inline-block;
    clear: both;
    .avatar-box{
        position: absolute;
        left:1.25rem;
        top:-1.5rem;
        .avatar{
            position: relative;
            width:4.375rem;
            height:4.375rem;
            border-radius: 4.375rem;
            border: 1px solid #A21B26;
        }
        .name{
            position: absolute;
            right:-0.625rem;
            bottom:0;
            font-size:0.625rem;
            width: 1.625rem;
            border-radius: 1.875rem;
            padding:1px 4px;
            background: rgba(255, 255, 255, 0.5);
            border: 1px solid rgba(200, 168, 121, 1);
            color:rgba(200, 168, 121, 1);
        }
    
        img{
            width:100%;
            border-radius: 4.375rem;
        }
    }
   .btn{
        font-size:0.75rem;
        float: right;
        border-radius: 3.75rem;
        color:#A21B26;
        border: 1px solid #A21B26;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 10px 2px 10px;
        img{
            margin-left:0.3125rem;
            width: 12px;
        }
    }
    .demo-ruleForm{
        margin-top:2rem;
    }
}
</style>