//时分秒格式时间转换
export function convertTimeToDateTime(timeStr) {
    let now = new Date();
    now.setFullYear(1970, 0, 1); // 使用 1970 年 1 月 1 日作为日期基准
    if (!timeStr) {
      timeStr = '00:00:00';
    }
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    now.setHours(hours);
    now.setMinutes(minutes);
    now.setSeconds(seconds);
    return now;
}