<template>
    <!-- 登录 -->
    <div class="login-container">
        <div class="login-box">
            <h3>ログインへようこそ</h3>
            <el-form :model="ruleForm"  ref="ruleForm" label-position="top" class="demo-ruleForm">
                <el-form-item label="ユーザー名です" prop="username">
                    <el-input  v-model="ruleForm.username" autocomplete="off" placeholder="ユーザー名の入力をお願いします"></el-input>
                </el-form-item>
                <el-form-item label="パスワード" prop="password">
                    <el-input  v-model="ruleForm.password" type="password" autocomplete="off" placeholder="パスワードを入力してください"></el-input>
                </el-form-item>
            </el-form>
            <el-button @click="login">
                ログイン
            </el-button>
            <p>パスワードを忘れた場合は管理者に連絡してください</p>
        </div>
        <el-dialog
            title="席をお願いします"
            :visible.sync="stValid"
            width="70%">
            <ul class="tablelist">
                <li v-for="(value,key,index) in tables" :key="index" @click="handleSeltable(key)">
                    {{ value }}
                </li>
            </ul>
        </el-dialog>
    </div>
</template>
<script>
import {loginpad } from '@/api/server.js'
    export default {
        data() {
            return {
                stValid: false,
                ruleForm: {},
                tables: [],
                sid: 0,
                tid: 0
            }
        },
        mounted(){
            // this.enterFullscreen()
        },
        methods:{
            // enterFullscreen() {
            //     const element = document.documentElement;
            //     if (element.requestFullscreen) {
            //         element.requestFullscreen();
            //     } else if (element.mozRequestFullScreen) {
            //         element.mozRequestFullScreen();
            //     } else if (element.webkitRequestFullscreen) {
            //         element.webkitRequestFullscreen();
            //     } else if (element.msRequestFullscreen) {
            //         element.msRequestFullscreen();
            //     }
            // },
            //登录
            async login(){
                if(this.ruleForm.username == ''){
                    this.$message({
                        message: 'ユーザー名を入力してください',
                        type: 'error',
                        duration: 2000,
                    });
                }else if(this.ruleForm.password == ''){
                    this.$message({
                        message: 'パスワードをお願いします',
                        type: 'error',
                        duration: 2000,
                    });
                }else{
                    try {
                        const res = await loginpad(this.ruleForm);
                        this.$message({
                            type:'success',
                            duration: 2000,
                            message:res.msg
                        });
                        this.$store.commit('setUserInfo', {
                            userInfo: {avatar:res.data.avatar,token:res.data.token}
                        });
                        this.sid = res.data.storeid
                        this.tables = res.data.table
                        this.stValid = true
                    } catch (error) {
                        // console.log('error');
                    }     
                }
            },
            handleSeltable(id) {
                this.tid = id
                this.$router.push({
                    path: '/',
                    query: {
                        tid:this.tid,
                        sid:this.sid
                    }
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner{
    border:none !important;
    background-color: rgba(248, 249, 251, 1) !important;
}
::v-deep .el-form-item{
    margin-bottom:0;
}
::v-deep .el-button,.el-button:hover,.el-button:focus{
    background-color: #A21B26;
    color:#FFFFFF;
    border:none;
    border-radius: 30px;
    width:80%;
    margin-top:2.75rem;
    font-size:1.125rem;
}
::v-deep .el-form-item__label{
    font-size:1.125rem !important;
}
::v-deep .el-form--label-top .el-form-item__label{
    float:left;
    padding:0;
}
.login-container{
    background: url("@/assets/left.png")no-repeat top / 100% 100%;
    height:100vh;
    box-sizing: border-box;
}
.arrow{
    text-align: left;
    padding:2.5%;
    img{
        width: 20px;
        margin-top:20px;
    }
}
.login-box{
    padding:20px;
    border-radius: 10px;
    box-sizing: border-box;
    width:45%;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    box-shadow: 0rem .3125rem .6875rem  rgba(212, 48, 48, 0.15);
    h3{
        font-size:1.875rem;
    }
    p{
        font-size:.75rem;
        color:rgba(153, 153, 153, 1);
        margin-top:.5rem;
        text-align: center;
    }
    .Toggle,p>span{
        color:#A21B26;
    }
}
::v-deep .el-message{
    min-width: 17.5rem !important;
}
.tablelist {
    display:flex;
    li {
        font-size:1rem;
        line-height:1rem;
        cursor: pointer;
        margin-right: 1rem;
        padding:5px 10px;
        border-radius: 5px;
        background-color: antiquewhite;
    }
}
::v-deep .el-dialog__body{
    padding-top:10px !important;
}
</style>