<template>
  <!-- 首页 -->
  <div class="home">
    <sidebar :shop="information" @childClick="childClick" :active="active"></sidebar>
    <div class="content" >
      <Nav :tableNumber="information.table" :store="information.store" @searchClick="searchClick" @callServer="callServer" @getQrcode="getQrcode"></Nav>
      <div class="product" draggable="false">
        <div>
          <p v-if="active == -1" style="margin-top:20px;width:100%;text-align:center;">当面は食事の時間外です</p>
          <ul v-if="menulv2.length > 0">
            <li v-for="(item,index) in menulv2" :key="index" 
            :class="start == index?'active-start':''" @click="startClick(index,item)"
            >
              <span>{{ item.title }}</span>
            </li>
          </ul>
          <div class="product-list" @scroll = "handleScroll" ref="scrollableDiv" v-loading="loading" :class="menulv2.length > 0?'product-list-min':'product-list-bg'"> 
            <div class="product-list-start" :class="orderList&&addactivea?'addactive':''">
              <p>食事済み</p>
              <div class="p-flex">
                <p>食事人数：<span>{{ orderList&&orderList.count?orderList.count:'0' }}</span></p>
                <p>合計金額：<span>￥{{  orderList&&orderList.price?orderList.price:'0.00' }}</span></p>
              </div>
              <div class="product-list-start-box" v-if = "orderList">
                <div  v-for="(item,index) in orderList.orderdishe" :key="index" class="cart-item"> 
                  <div class="cart-img">
                    <img  v-lazy="item.thumb?item.thumb:'@/assets/cthumb.gif'"   alt="middle">
                  </div>
                  <div class="cart-content">
                    <p>{{ item.title }}</p>
                    <div>
                      <span class="price">￥<span>{{ item.price }}</span></span>
                      <p class="product-item-btn">
                        X{{ item.count }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="product-list-bg" :class="orderList&&!addactivea?'bgcolor':orderList&&addactivea?'bgcolor-a':''">
              <div class="product-list-bg-img" @click="addactive()">
                <img src="@/assets/meishi1.png" alt="" v-if="orderList&&addactivea">
                <img src="@/assets/meishi.png" alt="" v-else>
                <p>{{ addnum }}</p>
              </div>
              <div class="bg-text" :class="orderList&&!addactivea?'bgtext':orderList&&addactivea?'bgtext-a':''" @click="settleAccounts">
                お会計
                <span v-if="isCountingDown">(</span>{{countdownText}}<span v-if="isCountingDown">)</span>
              </div>
            </div>
            <div class="place-an-order" >
              <div @click="ender(1)">
                <img src="@/assets/meishi.png" alt="" >
                <p>{{ addnuma }}</p>
              </div>
              <div @click="ender(2)">
                注文に行く
              </div>
              <div @click="settleAccounts" :class="{ button: !isCountingDown, 'button-countdown': isCountingDown }">
                お会計
                <span v-if="isCountingDown">(</span>{{countdownText}}<span v-if="isCountingDown">)</span>
              </div>
            </div>
            <!-- 移动端样式 -->
            <div class="place-a" :class="show? 'place-a-active' : ''">
              <p>
                {{typestart ==1?'注文済み':typestart ==2?'お選びしました':''}}
                <img src="@/assets/off-1.png" alt="" @click="off" v-if="typestart == 2">
                <img src="@/assets/laji.png" alt="" @click="clear()" v-if="typestart == 1">
              </p>
              <div v-if ="typestart ==1" class="product-list-start-box">
                <div v-for="(item,index) in addlist" :key="index" class="cart-item"> 
                  <div class="cart-img">
                    <img v-lazy="item.thumb?item.thumb:'@/assets/cthumb.gif'"   alt="">
                  </div>
                  <div class="cart-content">
                    <p>{{ item.title }}</p>
                    <p v-if="item.selfselect == 2" class="parts">{{ item.partone }} + {{ item.parttwo }}</p>
                    <div>
                      <p><span class="price">￥</span><span>{{ item.price }}</span></p>
                      <p class="product-item-btn">
                        <img src="@/assets/minus.png" alt="" @click="sub(item,1,index)"><span>{{ item.count }}</span> <img src="@/assets/add1.png" alt="" @click="add(item,1,index)">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-list" v-if ="typestart ==2 && orderList">
                <div v-for="(item,index) in orderList.orderdishe" :key="index" class="cart-item"> 
                  <div class="cart-img">
                    <img v-lazy="item.thumb?item.thumb:'@/assets/cthumb.gif'"   alt="">
                  </div> 
                  <div class="cart-content">
                    <p>{{ item.title }}</p>
                    <p class="parts">{{ item.parts?item.parts:"" }}</p>
                    <div>
                      <p><span class="price">￥</span><span>{{ item.price }}</span></p>
                      <p>
                        X{{ item.count }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-subtotal" v-if="show&&typestart ==2&&orderList">
                <p>商品の合計：<span>X{{ addnum }}</span></p>
                <p>食事人数：<span>{{ orderList.count }}</span></p>
                <p>合計金額：<span><span class="money">￥</span>{{ orderList.price }}</span></p>
              </div>
            </div>
            <el-row :gutter="10">
              <el-col :span="24" v-if="!productlist&&active != -1&&!loading||productlist.length == 0&&active != -1&!loading">
                <p>しばらく料理はありません</p>
              </el-col>
              <el-col :xs="24" :lg="6" :sm="12" :md="8" v-for="(item,index) in productlist" :key="index" >
                <div class="product-item">
                  <div class="product-img-box">
                    <img v-lazy="item.thumb?item.thumb:'@/assets/cthumb.gif'"   alt="" @click="dialog(item)" class="product-img" >
                    <!-- <div class="product-img-text" v-if="item.selfselect == 2">
                      選択
                    </div> -->
                  </div>
                  <div class="product-item-info">
                    <p>{{ item.title }}</p>
                    <div class="price-box">
                      <span class="price">￥<span>{{ item.price }}</span></span>
                      <div class="product-item-btn" v-if="item.selfselect == 1">
                        <img src="@/assets/minus.png" alt="" @click="sub(item,1)" v-if="item.count > 0"><span v-if="item.count > 0">{{ item.count }}</span> <img src="@/assets/add.png" alt="" @click="add(item,1)">
                      </div>
                      <div class="product-item-add" v-if="item.selfselect == 2">
                        <img src="@/assets/add.png" alt="" @click="add(item,0)">
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div v-if="isLoading&&productlist.length>0" class="isbottom">
              <img src="@/assets/jiazai.png" alt="">
              <span>ロード中、しばらくお待ちください</span>
            </div>
            <div v-if="!isLoading&&productlist&&productlist.length>0&&!hasMore" class="isbottom">
              <span>これ以上の料理はありません</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cart">
        <div class="cart-title">
          お選びしました
          <img src="@/assets/shang.png" alt="" @click="clear()">
        </div>
        <div class="cart-list cart-list-pc" v-if="addlist">
          <div v-for="(item,index) in addlist" :key="index" class="cart-item"> 
            <div class="cart-img">
              <img v-lazy="item.thumb?item.thumb:'@/assets/cthumb.gif'"   alt="">
            </div>
            <div class="cart-content">
              <p>{{ item.title }}</p>
              <p v-if="item.selfselect == 2" class="parts">{{ item.partone }} + {{ item.parttwo }}</p>
              <span class="price-one">￥<span>{{ item.price }}</span></span>
              <div>
                <p class="product-item-btn">
                  <img src="@/assets/minus.png" alt="" @click="sub(item,1,index)"><span>{{ item.count }}</span> <img src="@/assets/add1.png" alt="" @click="add(item,1,index)">
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="cart-subtotal cart-subtotal-pc">
          <el-button :disabled="!(addlist&&addlist.length > 0)" :class="{ button:true,'button-color':!addlist }" @click="addOrder()">
            {{addlist.length > 0?"送信する" : "未註文です"}}
          </el-button>
        </div>
      </div>
    </div>
    <Dialog :dialogVisible="dialogVisible" @close="close" @verify="verify" :width="pageWidth>=768?'30%':'90%'"></Dialog>
    <detailDialog :dialogVisible="imgDialog" @close="closedetail" :width="pageWidth>768?'28%':'90%'">
      <div class="img-box">
        <img src="@/assets/off.png" alt="" class="off" @click="closedetail">
        <img v-lazy="imgtext.thumb?imgtext.thumb:'@/assets/cthumb.gif'"   alt="">
        <h4>{{ imgtext.title }}</h4>
        <p>
          <span class="span-text">概要：</span>
          {{ imgtext.desc }}
        </p>
      </div>
    </detailDialog>
    <detailDialog :dialogVisible="qrcodeDialog" @close="qrcodeetail" :width="pageWidth>768?'28%':'90%'">
      <div class="img-box">
        <img src="@/assets/off.png" alt="" class="off" @click="qrcodeetail">
        <img :src="qrcode" v-if="qrcode"   alt="">
      </div>
    </detailDialog>
    <detailDialog :dialogVisible="mealdialog" @close="mealdetail" :width="pageWidth>=768?'68%':'90%'"> 
      <div class="meal-box">
        <img src="@/assets/off.png" alt="" class="off" @click="mealdetail">
        <img v-lazy="mealtext.thumb?mealtext.thumb:'@/assets/cthumb.gif'"   alt="">
        <div class="meal-content">
          <h4>{{ mealtext.title }}</h4>
          <el-form label-position="top">
            <el-form-item   label="選択" class="form-left">
              <el-radio-group v-model="mealtext.partone1">
                <el-radio  v-for="(item,index) in mealtext.partone" :key="index" :label="item"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <el-form label-position="top">
            <el-form-item  label="選択" class="form-left">
              <el-radio-group v-model="mealtext.parttwo2">
                <el-radio  v-for="(item,index) in mealtext.parttwo" :key="index" :label="item"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="product-item-btn">
            <img src="@/assets/minus.png" alt="" @click="sub(mealtext)"><span>{{ mealtext.count }}</span> <img src="@/assets/add.png" alt="" @click="adda(mealtext)">
            <el-button class="Place" @click="meal(mealtext)">注文の確認</el-button>
          </div>
        </div>
      </div>
    </detailDialog>
    <div class="mask" v-if="mask" @click="off"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Echo from 'laravel-echo';
import Sidebar from '../components/sidebar.vue';
import Nav from '../components/nav.vue';
import Dialog from '../components/dialog.vue';
import detailDialog from '../components/detailDialog.vue';
import { getIndexInfo,getDishList,order,addMeal,callServer,orderInfo,getQrcode } from '@/api/server.js'
import { baseMap } from '@/utils/config.js'
import { convertTimeToDateTime } from '@/utils/tools.js'
import { Loading } from 'element-ui'
export default {
  name: 'HomeView',
  components: {
    Sidebar,
    Nav,
    Dialog,
    detailDialog
  },
  data() {
    return {
      qrcodeDialog: false,
      sid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',//门店ID
      tid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:'',//餐位ID
      isLoading: false,
      hasMore: true,
      loading: false,
      isCountingDown: false,//倒计时状态
      countdownDuration: 10,//倒计时初始值
      remainingTime: 0,//当前倒计时剩余时间
      form:"",
      productlist:[],//菜品列表
      limit:20,
      page:1,
      information:{},//首页信息
      mask:false,
      mealdialog:false,
      start: 0,
      addlist:[],//选择菜品列表
      diners:"",//用餐人数
      dialogVisible:false,
      addactivea:false,
      imgDialog:false,
      active:0,
      imgtext:{},
      mealtext:{},
      show:false,
      typestart:0,
      pageWidth:'',
      parent_id:0,
      menulv2:[],//二级导航
      cid:'',
      orderList:{},//餐位订单数据
      addorder:false,
      point:false, //防连点
      search:"",
      qrcode:""
    }
  },
  watch: {
    addlist(val){
      if(val.length == 0&&!this.order){
        this.show = false;
        this.mask = false;  
      }
    }
  },
  computed: {
    countdownText() {
      return this.isCountingDown ? `${this.remainingTime}s` : '';
    },
    addnuma(){
      let total = 0;
      if(this.addlist&&this.addlist.length>0){
        this.addlist.forEach(item => {
            total += item.count
        })
      }
      return total
    },
    addnum(){
      let total = 0;
      if(this.orderList&&this.orderList.orderdishe&&this.orderList.orderdishe.length>0){
        this.orderList.orderdishe.forEach(item => {
          total += item.count
        })
      }else{
        total = 0;
      }
      return total
    }
  },
  created() {
  },
  mounted() {
    // this.enterFullscreen();
    this.getIndexInfo();
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    window.addEventListener('scroll', this.handleScroll);
    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key:'7a78e886f91aaa351b61',
        cluster:'ap3',
        wsHost:baseMap.base,
        wsPort:'6001',
        forceTLS: false
    });
    window.Echo.channel('AppNotification'+'.'+this.sid+'.'+this.tid).listen('AppMessage', (e) => {
      if(e.code == 3){
        this.orderInfo()
        return
      }else if(e.code == 1){
        this.orderInfo()
        this.addlist = []
        return
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // enterFullscreen() {
    //   const element = document.documentElement;
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen()
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen();
    //   } else if (element.webkitRequestFullscreen) {
    //     element.webkitRequestFullscreen();
    //   } else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen();
    //   }
    // },
    clear(){
      this.$confirm('食事を空にしますか', {
          distinguishCancelAndClose: true,
          confirmButtonText: '確認します',
          cancelButtonText: '取り消します',
          confirmButtonClass:'confirmButton'
        })
          .then(() => {
            this.productlist.map(item=>{
              if(item.selfselect == 1){
                item.count = 0
              }else{
                item.count = 1
              } 
            })
            this.addlist = [],
            this.addactivea = false
          })
          .catch();
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollableDiv;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;
      const clientHeight = scrollContainer.clientHeight;
      const isBottom = scrollTop + clientHeight >= scrollHeight;
      if (isBottom && !this.isLoading && this.hasMore) {
        this.getDishList(true);
      }
    },

                        
    //获取餐位订单数据
    async orderInfo(){
      let params = {
        ...this.$router.currentRoute.query,
      }
      try{
        const res = await orderInfo(params)
        if(res.code == 1){
          this.orderList = res.data
        }
      } catch (error) {
          // console.log('error');
      }   
      
    },
    //呼叫服务员
    async callServer(){
      try{
        let params = {
          calltype:0,
          ...this.$router.currentRoute.query,
        }
        const res = await callServer(params)
        if(res.code == 1){
          this.$message({
            message: res.msg,
            type: 'success'
          });
        }
      } catch (error) {
          // console.log('error');
      } 
    },
    async getQrcode(){
      let loadingInstance = Loading.service({lock:true})
      try{
        let params = {
          sid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
          tid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
        }
        const res = await getQrcode({...params})
        this.qrcode = res.data
        this.qrcodeDialog = true
        this.$nextTick(() => {
          loadingInstance.close()
        })
      }catch(error) {
        this.$nextTick(() => {
          loadingInstance.close()
        })
      } 
    },
    qrcodeetail(){
      this.qrcodeDialog = false
    },
    //获取首页二级导航
    childClick(item,index,e){
      const scrollContainer = this.$refs.scrollableDiv;
      scrollContainer.scrollTop = 0;
      this.active = index;
      this.parent_id = item;
      this.start = e;
      this.menulv2 = this.information.menulv2.filter(item => {
        return item.parent_id == this.parent_id
      });
      this.cid = this.menulv2&&this.menulv2.length>0?this.menulv2[0].id:'';
      this.page = 1;
      this.getDishList()
    },
    searchClick(item){
        this.search = item;
        this.page = 1;
        this.getDishList()
    },
    //获取首页菜品列表
    async getDishList(e){
      if(!e){
        this.loading = true;
      }
      this.isLoading = true;
      try{
        let params = {
          sid:this.sid,
          vcode:this.$router.currentRoute.query.vcode,
          limit:this.limit,
          page:this.page,
          keyword:this.search,
          cid:this.cid?this.cid:this.parent_id
        }
        const res = await getDishList({...params})
          if(!e){
            this.productlist = res.data.list
          }else{
            this.productlist.push(...res.data.list);
          }
          this.page++;
          if(this.productlist&&this.productlist.length>0){
            this.productlist.map(item=>{
              if(item.selfselect == 1){
                item.count = 0
              }else{
                item.count = 1
              } 
            })
            if(this.addlist&&this.addlist.length>0){
              // 遍历 this.addlist
              this.addlist.forEach(addItem => {
                // 在 this.productlist 中查找具有相同 id 的项
                const productItem = this.productlist.find(productItem => productItem.id === addItem.id);
                // 如果找到了匹配项，则将 addlist 中的 count 值赋给 productlist
                if (productItem&&productItem.selfselect == 1) {
                  productItem.count = addItem.count;
                }
              });
            }
          }
          if(res.data.list.length < this.limit){
            this.hasMore = false;
          }else{
            this.hasMore = true;
          }
          this.loading = false;
          this.isLoading = false;
      } catch (error) {
          this.isLoading = false;
          // console.log('error');
      }
    },
    // 获取首页信息
    async getIndexInfo() {
      try{
        const res = await getIndexInfo(this.$router.currentRoute.query)
        this.information = res.data;
        let time1 = new Date();
        time1.setFullYear(1970, 0, 1);
        if(this.information.menulv1&&this.information.menulv1.length>0){
            for (let i = 0; i < this.information.menulv1.length; i++) {
                const record = this.information.menulv1[i];
                let endtime = convertTimeToDateTime(record.endtime?record.endtime:'23:59:59');
                let starttime = convertTimeToDateTime(record.starttime?record.starttime:'00:00:00');
                if (time1 >= starttime && time1 <= endtime) {
                    this.active = i;
                    break; // 找到一个匹配的就跳出循环
                }else{
                    this.active = -1;
                    break;
                }
            }
        }
        if(this.active != -1){
          this.parent_id = this.information.menulv1[this.active].id?this.information.menulv1[this.active].id:'';
          this.menulv2 = this.information.menulv2.filter(item => {
            return item.parent_id == this.parent_id
          })
          this.cid = this.menulv2&&this.menulv2.length>0?this.menulv2[0].id:'';
          this.page = 1;
          this.getDishList()
        }
        this.orderInfo()
      } catch (error) {
          // console.log('error');
      }  
    },
    updateWidth() {
      this.pageWidth = window.innerWidth;
    },
    off(){
      this.show=false;
      this.mask=false;
    },
    //下单
    ender(item){
      this.typestart = item;
      if(item == 2){
        if(this.orderList&&this.addlist&&this.addlist.length>0){
          this.addOrder(true);
        }else if(this.orderList&&this.orderList.orderdishe&&this.addlist.length==0){
          this.show = !this.show; 
          this.mask = !this.mask;
        }else if(this.addlist&&this.addlist.length>0&&!this.orderList){
          this.show = false; 
          this.mask = false;
          this.dialogVisible = true
        }
      }else if(this.addlist&&this.addlist.length>0 || this.addlist&&this.addlist.length>0&&item == 2&&!this.dialogVisble){
        this.show = !this.show;
        this.mask = !this.mask;
      }
    },
    closedetail(){
      this.imgDialog = false;
    },
    mealdetail(){
      this.mealdialog = false;
    },
    dialog(item){
      this.imgDialog = true;
      this.imgtext = item;
    },
    meal(item){
      if(!item.partone1 || !item.parttwo2){
        this.$message.error('まずお食事をお選びください')
      }else if(item.count == 0){
        this.$message.error('セット数をお選びください')
      }else{
        this.addlist.push({
          ...this.mealtext,
          count:item.count,
          partone:item.partone1,
          parttwo:item.parttwo2,
          partone1:undefined,
          parttwo2:undefined,
          desc:undefined
        })
        this.mealdialog = false;
      }
    },
    addactive(){
      if(this.orderList&&this.orderList.orderdishe&&this.orderList.orderdishe.length>0){
        this.addactivea = !this.addactivea;
      }else{
        return
      }
      
    },
    startClick(index,item){     
      this.$nextTick(() => {
        this.start = index;
      })
      this.cid = item.id;
      this.page = 1;
      this.getDishList()
    },
    close(){
      this.diners = "";
      this.dialogVisible = false;
    },
    // 验证
    async verify(val){
      if(this.point){
        return;
      }
      this.point = true;
      this.diners = val;
      if(this.diners==''&&!this.orderList){
        this.$message({
          message: '食事人数を入力してください',
          type: 'warning'
        });
        this.point = false;
      }else{
        //点单
        try{
          let params = {
            ...this.$router.currentRoute.query,
            count:this.diners,
            orderdishe:this.addlist?this.addlist:[]
          }
          const res = await order(params);
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.orderInfo();
            this.page = 1;
            if(this.productlist&&this.productlist.length>0){
              this.productlist.map(item=>{
                item.count = 0;
              })
            }
            this.addlist = [];
            this.addactivea = false;
            this.order = true;
            this.show = true;
            this.mask = true;
            this.point = false;
            this.diners = ''
        } catch (error) {                  
            // console.log('error');
            this.point = false;
        }
        this.point = false;
        this.dialogVisible = false;
        this.show=true;
        this.mask=true;
      }
      this.point = false;
    },
    async addOrder(){
      if (this.point) return 
      this.point = true;
      if(this.addlist.length > 0 && !this.orderList){
        this.dialogVisible = true;
      }else if (this.addlist.length > 0 && this.orderList){
        let orderdishe = [];
        this.addlist.forEach(item => {
          orderdishe.push({
            id:item.id,
            count:item.count,
            partone:item.partone?item.partone:undefined,
            parttwo:item.parttwo?item.parttwo:undefined
          })
        })
        
        let params = {
          ...this.$router.currentRoute.query,
          oid:this.orderList.id,
          orderdishe:orderdishe?orderdishe:[]
        }
        //加单
        try{
          const res = await addMeal(params);
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.orderInfo();
            this.page = 1;
            if(this.productlist&&this.productlist.length>0){
              this.productlist.map(item=>{
                item.count = 0;
              })
            }
            this.addlist = [];
            this.addactivea = false;
            this.order = true;
            this.show = true;
            this.mask = true;
            this.point = false;
        } catch (error) {
            this.point = false;
            // console.log('error');
        }
      }
      this.point = false;
    },
    //结账
    async settleAccounts(){
      if (!this.isCountingDown) {
        this.isCountingDown = true;
        this.remainingTime = this.countdownDuration;
        let params = {
          calltype:1,
          ...this.$router.currentRoute.query,
          oid:this.orderList?this.orderList.id:'',
        }
        this.startCountdown();
        try{
          const res = await callServer(params);
          if(res.code == 1){
            this.$message({
              message: res.msg,
              type: 'success'
            });
          }
        } catch (error) {
            // console.log('error');
        } 
      }  
    },
    startCountdown() {
      const countdownInterval = setInterval(() => {
        if (this.remainingTime > 1) {
          this.remainingTime--;
        } else {
          clearInterval(countdownInterval);
          this.isCountingDown = false;
          this.remainingTime = 0;
        }
      }, 1000); // 每秒更新一次倒计时
    },
    add(item,e,index1){
      if(item.selfselect == 1 && e == 1){
        const fruit = this.addlist.find(add => add.id === item.id)
        const fruitup = this.productlist.find(add => add.id === item.id)
        fruitup.count++
        if(fruit){
          fruit.count++
        }else{
          this.addlist.push({...item,count:item.count,desc:undefined})
        }
      }else if(e == 1 && item.selfselect == 2){
        const fruit = this.addlist.find((add,index) => add.id === item.id && index === index1)
        if(fruit){
          fruit.count++
        }
      }else{
        let newObj = JSON.parse(JSON.stringify(item))
        this.mealtext = newObj
        this.mealdialog = true;
      } 
    },
    adda(){
      this.$set(this.mealtext,'count',this.mealtext.count+1)
    },
    sub(item,e,index1){
      this.addorder = false;
      if(item.selfselect == 1 && e == 1){
        const fruit = this.addlist.find(add => add.id === item.id)
        const fruitup = this.productlist.find(add => add.id === item.id)
        fruitup.count--
        if(fruit){
          fruit.count--
          if(fruit.count<=0){
            this.addlist.splice(this.addlist.indexOf(fruit),1)
            if(this.addlist.length==0){
              this.addactivea = false;
            }
          }
        }
        if(fruitup.count<=0){
          fruitup.count = 0
        }
      }else if(e == 1 && item.selfselect == 2){
        const fruit = this.addlist.find((add,index) => add.id === item.id && index === index1)
        if(fruit){
          fruit.count--
          if(fruit.count<=0){
            this.addlist.splice(this.addlist.indexOf(fruit),1)
            if(this.addlist.length==0){
              this.addactivea = false;
            }
          }
        }
      }else{
        this.mealtext.count--
        if(this.mealtext.count<=1){
          this.mealtext.count = 1
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .home {
    overflow: hidden;
  }
  .isbottom{
    img{
      vertical-align: middle;
    }
    margin-bottom:10px;
  }
  ::v-deep .el-row{
    margin:0 !important;
  }
  ::v-deep .el-loading-mask{
    background-color: #FFFFFF;
    z-index:1999;
  }
  ::v-deep .el-loading-spinner .path{
    stroke:#A21B26;
  }
  .Place,.Place:focus{
    background-color: #A21B26;
    font-size:0.875rem;
    color: #fff;
    padding:0.375rem 1rem;
    border-radius: 1.25rem;
    margin-left:2.3125rem;
  }
  .meal-box{
    padding:20px;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .off{
      width:30px;
      height:30px;
      position: absolute;
      top:-32px;
      right:-32px;
    }
    img{
      width:30%;
    }
    .meal-content{
      width:68%;
      .product-item-btn{
        position: absolute;
        bottom:20px;
        left:calc(30% + 10px)
      }
    }
  }
  .price-one{
    font-size:12px;
    color:#A21B26;
  }
  .img-box{
    padding:20px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    .off{
      width:1.875rem;
      height:1.875rem;
      position: absolute;
      top:-2rem;
      right:-2rem;
    }
    img{
      width:100%;
    }
    h4{
      font-size:1.25rem;
      color:#333333;
      margin:1.25rem 0;
      font-weight: 400;
    }
    p{
      font-size:0.875rem;
      margin-bottom:1.25rem;
      color:#666666;
      .span-text{
        color:#333333;
      }
    }
  }
  .content{
    width: calc(100% - 8.75rem - 15.875rem);
    margin-left:8.75rem;
  }
  .product{
    height:100vh;
    box-sizing: border-box;
    padding-top:60px;
    overflow: hidden;
  }
  .product>div:first-child{
    background-color: #F7F5F5;
    padding:10px;
    // box-sizing: border-box;
    height:100%;
    ul{
      overflow: hidden;
      overflow-x: scroll;
      height:40px;
      width:100%;
      display: flex;
      font-size:0.75rem;
      background-color: rgba(200, 168, 121, 1);
      padding:0.3125rem 0.4375rem;
      box-sizing: border-box;
      border-radius:1.25rem;
      
      li{
          width:18%;
          flex:none;
          padding:0 1.25rem;
          white-space: nowrap;
          text-align: center;
          line-height:1.875rem;
          color:rgba(249, 248, 244, 1);
      }
      .active-start{
        background-color: rgba(249, 248, 244, 1);
        color:rgba(200, 168, 121, 1);
        border-radius: 1.25rem;
      }
    }
    ul::-webkit-scrollbar{
      width:0;
      height:0;
    }
    .product-list{
      overflow-y: scroll; /* 允许内部滚动 */
      margin-top:0.625rem;
      height:100%;
      padding-bottom:80px;
      box-sizing: border-box;
      .product-list-start{
        height: 0;
        padding-bottom:0;
        background-color: #A21B26;
        position:fixed;
        bottom:0.625rem;
        right:16.5rem;
        border-radius: 1.875rem;
        width:20rem;
        padding-top:0;
        box-sizing: border-box;
        z-index:990;
        &>p{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size:1.125rem;
          color:#FFFFFF;
          margin:0 1.25rem;
          img{
            width:24px;
            vertical-align: middle;
          }
        }
      }
      .addactive{
        height:18.75rem;
        overflow-y: scroll;
        padding-bottom:2.5rem;
        padding-top:1.25rem;
        transition: all 0.2s;
      }
      // .addactive::-webkit-scrollbar {
      //     width:0;
      //     height: 0;
      // }
      .product-list-bg{
        width:20rem;
        background-color: rgba(229, 229, 229, 1);
        position:fixed;
        bottom:0.625rem;
        right:16.5rem;
        padding:0 0.315rem 0 2rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 1.875rem;
        z-index:2001;
        box-shadow: 0rem 0.125rem 0.25rem  rgba(0, 0, 0, 0.25);
        .product-list-bg-img{
          width:2.625rem;
          position: relative;
          padding:5px 0;
          img{
            width:100%;
            height:100%
          }
          p{
            width:20px;
            height:20px;
            border-radius: 50%;
            background: #FFFFFF;
            border: 0.125rem solid #A21B26;
            position: absolute;
            bottom:0.3125rem;
            right:-0.3125rem;
            font-size:0.875rem;
            color:#A21B26;
            line-height:20px;
          }
        }
        .bg-text{
          width:14rem;
          font-weight: 500;
          color:#333333;
          text-align: center;
          background-color: #FFFFFF;
          font-size:1.125rem;
          line-height:44px;
          margin-right:3px;
          border-radius: 20px;
          margin-left:0.8125rem;
        }
        .bgtext{
          color: #A21B26;
        }
        .bgtext-a{
          color:#FFFFFF;
          background-color: #A21B26;
        }
      }
      .bgcolor{
          background-color: #A21B26;
      }
      .bgcolor-a{
        background-color: #FFFFFF;
      }
      .product-item{
        margin-bottom:10px;
        width:100%;
        background-color: #FFFFFF;
        text-align:left;
        border-radius: 10px;
        .product-img-box{
          position: relative;
          overflow: hidden;
          .product-img-text{
            position: absolute;
            line-height:30px;
            padding:0 30px 20px;
            right:-35px;
            bottom:-15px;
            // background-color: #A21B26;
            background: linear-gradient(to bottom,#F34F00,#FDDA00);
            color: #FFFFFF;
            transform: rotate(-45deg);
          }
          img{
            border-radius: 10px 10px 0 0;
            width:100%;
            height:200px;
            max-height:200px;
            display:block;
            object-fit: contain;
          }
        }
        .product-img{
          height:auto;
        }
        .product-item-info{
          padding:14px 12px;
          font-size:0.875rem;
          .price-box{
            margin-top:10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .price{
              color:#A21B26;
              span{
                font-size:1.25rem;
              }
            }
            .product-item-add{
              img{
                width:20px;
                height:20px;
              }
            }
            
          }
          >p{
            height:40px;
            line-height:20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-line;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-box-align: center;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }
        }
      }
    }
  }
  .product-list-start-box{
    margin-top:20px;
    .cart-item{
        margin:0 1.25rem 0.625rem 1.25rem;
        display:flex;
        border-radius: 6px;
        background: #FFFFFF;
        border: 1px solid rgba(200, 168, 121, 1);
        .cart-img{
          width:45%;
          img{
            width:100%;
            height:100%;
            object-fit: cover;
            border-radius: 6px 0 0 6px;
          }
        }
      }
      .cart-content{
        width:55%;
        padding:0.625rem;
        font-size:0.875rem;
        position: relative;
        text-align: left;
        div{
          position: absolute;
          left:0.625rem;
          right:0.625rem;
          bottom:0.625rem;
          display: flex;
          align-content: center;
          justify-content: space-between;
          line-height:20px;
          span{
            color:#A21B26;
          }
          .product-item-btn{
              display: flex;
              align-items: center;
              font-size:14px;
              img{
                width:20px;
                margin-left:5px;
              }
              span{
                margin-left:5px;
              }
              span:nth-child(2){
                color:#333333 
              }
            }
        }
      }

  }
  .cart{
    width:15.875rem;
    position: fixed;
    right:0;
    top:60px;
    height:calc(100vh - 60px);
    overflow: hidden;
    z-index:99;
    .cart-subtotal{
      position: absolute;
      bottom:0;
      left:0.625rem;
      right:0.625rem;
      background: rgba(249, 248, 244, 1);
      border: 1px solid #A21B26;
      border-radius: 10px 10px 25px 25px;
      border-bottom:none;
      z-index:100;
      p{
        color:rgba(153, 153, 153, 1);
        font-size:14px;
        display: flex;
        justify-content: space-between;
        margin:10px;
      }
      p:first-child>span{
        color:#333333;
        font-size:18px;
      }
      p:nth-child(2)>span{
        color:#A21B26;
        font-size:20px;
      }
      .button{
        width:100%;
        border:none;
        border-radius: 10px 10px 25px 25px;
        background-color: #A21B26;
        color:#FFFFFF;
        font-size:20px;
      }
      .button-countdown{
        width:100%;
        border:none;
        border-radius: 10px 10px 25px 25px;
        background-color: #909399;
        color:#FFFFFF;
        font-size:20px;
      }
      .button-color{
        background-color: #909399;
      }

    }
    .cart-subtotal-pc{
      border-style: none;
      .button{
        border-radius: 25px;
        margin-bottom:5px;
      }
    }
    .cart-title{
      background: #A21B26;
      line-height:2.75rem;
      font-size:1.125rem;
      color:#FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:0 20px;
      img{
        width:24px;
        vertical-align: middle;
      }
    }  
  }
  .cart-list{
      margin-top:10px;
      overflow-y: scroll;
      height: (calc(100vh - 245px));
      padding:0 0.625rem 0 0.625rem;
      box-sizing: border-box;
    }
    .cart-list-pc{
      height: (calc(100vh - 165px));
    }
    .cart-list{
      .cart-item{
        margin-bottom:10px;
        display:flex;
        // height:5.625rem;
        border-radius: 6px;
        background: #FFFFFF;
        border: 1px solid rgba(200, 168, 121, 1);
        .cart-img{
          width:50%;
          img{
            object-fit: cover;
            width:100%;
            height:100%;
            border-radius: 6px 0 0 6px;
          }
        }
      }
      .cart-item:last-child{
        margin-bottom:0;
      }
      .cart-content{
        width:50%;
        box-sizing: border-box;
        padding:0.625rem;
        font-size:0.875rem;
        position: relative;
        text-align: left;
        div{
          width:calc(100% - 1.25rem);
          position: absolute;
          left:0.625rem;
          right:0.625rem;
          bottom:0.625rem;
          display: flex;
          align-content: center;
          justify-content: space-between;
          line-height:20px;
          span{
            color:#A21B26;
            visibility: top;
          }
        }
      }
    }
  .place-an-order,.place-a{
    display:none;
  }
  @media screen and (max-width: 767px){
   
    .content{
        width:100%;
        margin-left:0;
    }
    ::v-deep.el-col {
      padding-left:0 !important;
      padding-right:0 !important;
    }
    .cart{
      display:none;
    }
    .product>div:first-child{
        display: flex;
        flex-wrap: wrap;
        padding:10px 0 0 0;
        background-color: #FFFFFF;
        ul{
          width:20%;
          border-radius: 0;
          flex-wrap: wrap;
          background-color: rgba(247, 247, 247, 1);
          padding:0;
          display:block;
          height:auto;
          padding-bottom:70px;
          li{
            white-space: normal;
            width:100%;
            color:#333333;
            padding:10px 12px;
            line-height:20px;
            box-sizing: border-box
          };
          .active-start{
            border-radius: 0;
            background-color: #FFFFFF;
            color:#A21B26
          }
        }
        
        .product-list{
          overflow-y: scroll;
          margin-top:0;
          background-color: #FFFFFF;
          padding:0 15px 80px 15px;
          height:100%;
          box-sizing: border-box;
          .product-list-start,.product-list-bg{
            display:none;
          }
          &>p{
            font-size:0.875rem;
          }
          .product-item{
            border: 1px solid rgba(200, 168, 121, 1);
            border-radius: 5px;
            padding-top:0;
            display: flex;
            // height:6.25rem;
            .product-img-box{
              position: relative;
              overflow: hidden;
              width:45%;
              .product-img-text{
                position: absolute;
                line-height:30px;
                padding:0 30px 20px;
                right:-35px;
                bottom:-15px;
                // background-color: #A21B26;
                background: linear-gradient(to bottom,#F34F00,#FDDA00);
                color: #FFFFFF;
                transform: rotate(-45deg);
              }
              img{
                width:100%;
                height:100%;
                border-radius: 5px 0 0 5px;
                display: block;
                object-fit: fill;
              }
            }
            .product-img{
              height:100%;
            }
            .product-item-info{
              position: relative;
              width:55%;
              box-sizing: border-box;
              padding:14px 8px;
              &>p{
                font-size:16px;
              }
              .price-box{
                width:calc(100% - 16px);
                position: absolute;
                bottom:8px;
                left:8px;
                .price{
                  font-size:12px;
                  span{
                    font-size:16px;
                  }
                }
              }
              .product-item-btn{
                  font-size:16px;
                img{
                  width:20px;
                  margin-left:5px;
                }
                span{
                  margin-left:5px;
                }
              }
            }
          }
        }
        ::-webkit-scrollbar{
          display:none
        }
        .product-list-min{
          width:80%;
        }
        .product-list-bg{
          width:100%;
        }
      }
    .product{
      padding-top:8rem;
    }
    .place-a{
      display:block;
      width:100%;
      text-align:left;
      background-color: #FFFFFF;
      border-radius: 1.25rem 1.25rem 0 0 ;
      padding:0;
      height:0;
      position: fixed;
      box-sizing: border-box;
      bottom:0;
      left:0;
      z-index:99;
      .cart-subtotal{
        position: fixed;
        left:2.5%;
        right:2.5%;
        bottom:1.25rem;
        padding-bottom:3.125rem;
        background: #FFFFFF;
        border: 1px solid #A21B26;
        border-radius: 10px 10px 25px 25px;
        border-bottom:none;
        p{
          color:#333333;
          font-size:14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin:0.625rem;
        }
        p:first-child>span,p:nth-child(2)>span{
          font-size:1.1256rem;
        }
        p:nth-child(3)>span{
          font-size:1.625rem;
          .money{
            font-size:0.875rem;
          }
        }
      }
      p{
        font-size:1.25rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-content: center;
        img{
          width:20px;
          height:20px;
        }
      }
      .parts{
          color:#666666;
          font-size:0.625rem;
      }
    }
    .place-a-active{
      padding:0.9375rem 0.9375rem 75px 0.9375rem;
      height:80%;
      transition: all 0.5s;
    }
    
    .place-an-order{
      width:95%;
      height:50px;
      left:2.5%;
      z-index:2000;
      display:block;
      box-shadow: 0rem 0.125rem 0.25rem  rgba(0, 0, 0, 0.25);
      display:flex;
      align-items: center;
      position: fixed;
      bottom:1.25rem;
      background-color: rgba(189, 29, 26, 1);
      border-radius: 1.5625rem;
      div:first-child{
        background-color: #A21B26;
        border-radius: 1.5625rem;
        width:20%;
        box-sizing: border-box;
        padding:4px 20px 8px 20px;
        position: relative;
        img{
          width:2rem;
        }
        p{
          width:15px;
          height:15px;
          border-radius: 50%;
          background: #FFFFFF;
          border: 2px solid #A21B26;
          position: absolute;
          bottom:8px;
          right:15px;
          font-size:12px;
          color:#A21B26;
          line-height:15px;
        }
      }
      div:nth-child(2){
        width:40%;
        color:#FFFFFF;
        text-align:center;
        font-size:0.875rem;
      }
      .button{
        width:40%;
        color:#FFFFFF;
        background-color: rgba(201, 63, 60, 1);
        text-align:center;
        font-size:0.875rem;
        height:50px;
        line-height:50px;
        border-radius: 0 1.5625rem 1.5625rem 0;
      }
      .button-countdown{
        width:40%;
        color:#FFFFFF;
        background-color: #909399;
        text-align:center;
        font-size:0.875rem;
        height:50px;
        line-height:50px;
        border-radius: 0 1.5625rem 1.5625rem 0;
      }
      .button-color{
        background-color: #909399;
      }
   }
   .product-list-start-box{
      height:90%;
      overflow-y: scroll;
      .cart-item{
        padding:0;
      }
    }
    .cart-list{
      padding: 10px 0 200px 0;
      .cart-item{
        border:none;
      }
    }
    .cart-list,.product-list-start-box{
      .cart-item{
        margin:0 0 0.625rem 0;
        .cart-content{
          width:50%;
          &>p:first-child{
            font-size:1rem;
          }
          div{
              width:calc(100% - 1.25rem);
              p{
                .price{
                  font-size:12px;
                }
                font-size:1rem;
              }
              
          }
        }
      }
    }
    .mask{
      width:100%;
      height:100vh;
      position:fixed;
      top:0;
      left:0;
      opacity:0.5;
      background: #333333;
      z-index:10;
    }
    .meal-box,.img-box{
      .off{
        top:-2.125rem;
        right:0;
      }
    }
    .meal-box{
      &>img{
        width:100%;
      }
      .meal-content{
        width:100%;
        margin-top:10px;
        .product-item-btn{
          margin-top:10px;
          position: static;
          img:first-child{
            margin-left:0;
          }
        }
      }
    }
 }

 ::v-deep .el-radio__inner{
  width:0px;
  height:0px;
  border:none;
}
::v-deep .el-radio{
  margin-right:0.625rem;
  margin-bottom:0.625rem;
  border-radius: 0.25rem;
  background: rgba(200, 168, 121, 0.1);
  color:rgba(200, 168, 121, 1);
  padding:0.25rem 0.5625rem;
}
::v-deep .is-checked{
  background-color: rgba(200, 168, 121, 1);
  
}
.form-left{
  text-align: left;
  ::v-deep .el-form-item__content{
    line-height:0;
  }
}
::v-deep .el-form-item{
  margin-bottom:0;
}
::v-deep.el-form--label-top .el-form-item__label{
  padding:0;
}

::v-deep .el-radio__input.is-checked+.el-radio__label{
  color:#FFFFFF !important; 
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after{
  transform:translate(-50%,-50%) scale(0) !important;
}
::v-deep .el-radio__label{
  padding:0 !important;
}
.parts{
  color:#666666;
  font-size:0.625rem;
}
.confirmButton{
  background-color: #A21B26 !important;
  border-color: #A21B26 !important;
}
.product-item-btn{
    display: flex;
    align-items: center;
    font-size:20px;
    p,img,span{
      margin-left:10px;
    }
    img{
      width:20px;
      height:20px;
    }
  }
  .p-flex{
    display: flex;
    font-size:14px;
    padding:0 1.25rem;
    color:#FFFFFF;
    justify-content: space-between;
    margin-top:5px;
  }
</style>
