<template>
    <!-- 登录 -->
    <div class="login-container" >
        <p class="arrow">
            <img src="@/assets/arrow-left.png" alt="" @click="back()">
        </p>
        <div class="login-box" v-if="!logina">
            <h3>登録</h3>
            <el-form :model="ruleForm"  ref="ruleForm" label-position="top" class="demo-ruleForm">
                <el-form-item label="アカウント" prop="username">
                    <el-input  v-model="ruleForm.username" autocomplete="off" placeholder="ユーザー名の入力をお願いします"></el-input>
                </el-form-item>
                <el-form-item label="パスワードの設定" prop="password">
                    <el-input  v-model="ruleForm.password" autocomplete="off" type="password" placeholder="パスワードを入力してください"></el-input>
                </el-form-item>
                <el-form-item label="パスワードの確認" prop="passworda">
                    <el-input  v-model="ruleForm.passworda" autocomplete="off" type="password" placeholder="パスワードを再入力してください"></el-input>
                </el-form-item>
            </el-form>
            <el-button @click="register">
                登録の確認
            </el-button>
            <p><span @click="Toggle(0)">ログインしてください</span></p>
        </div>
        <div class="login-box" v-if="logina">
            <h3>ログインへようこそ</h3>
            <el-form :model="ruleForm"  ref="ruleForm" label-position="top" class="demo-ruleForm">
                <el-form-item label="ユーザー名です" prop="username">
                    <el-input  v-model="ruleForm.username" autocomplete="off" placeholder="ユーザー名の入力をお願いします"></el-input>
                </el-form-item>
                <el-form-item label="パスワード" prop="password">
                    <el-input  v-model="ruleForm.password" type="password" autocomplete="off" placeholder="パスワードを入力してください"></el-input>
                </el-form-item>
            </el-form>
            <el-button @click="login">
                ログイン
            </el-button>
            <p @click="Toggle(1)" class="Toggle">登録</p>
            <p>パスワードを忘れた場合は管理者に連絡してください</p>
        </div>
    </div>
</template>
<script>
import { register,login } from '@/api/server.js'
    export default {
        data() {
            return {
                ruleForm: {},
                logina:true,
            }
        },
        methods:{
            back(){
                this.$router.back()
            },
            async register(){
                if(!this.ruleForm.username){
                    this.$message({
                        type:'error',
                        duration: 2000,
                        message: 'ユーザー名を入力してください'
                    });
                    return
                }else if(!this.ruleForm.password){
                    this.$message({
                        type:'error',
                        duration: 2000,
                        message: 'パスワードをお願いします'
                    });
                    return
                }else if(this.ruleForm.password != this.ruleForm.passworda){
                    this.$message({
                        type:'error',
                        duration: 2000,
                        message: 'パスワードの入力が2回一致しません'
                    });
                    return
                }else{
                    try{
                        const res = await register(this.ruleForm)
                        if(res.code == 1){
                            this.$message({
                                type:'success',
                                duration: 2000,
                                message: '登録できました,ログインしてください'
                            });
                            this.logina = true;
                            this.ruleForm = {}
                        }
                    } catch (error) {
                        // console.log('error');
                    }  
                }
            },
            Toggle(e){
                if(e == 1){
                    this.logina = false;
                    this.ruleForm = {}
                }else{
                    this.logina = true;
                    this.ruleForm = {}
                }   
            },
            async login(){
                if(this.ruleForm.username == ''){
                    this.$message({
                        message: 'ユーザー名を入力してください',
                        type: 'error',
                        duration: 2000,
                    });
                }else if(this.ruleForm.password == ''){
                    this.$message({
                        message: 'パスワードをお願いします',
                        type: 'error',
                        duration: 2000,
                    });
                }else{
                    try {
                        const res = await login(this.ruleForm);
                        this.$message({
                            type:'success',
                            duration: 2000,
                            message:res.msg
                        });
                        // const {avatar,token} = response.data;
                        this.$store.commit('setUserInfo', {
                            userInfo: {avatar:res.data.avatar,token:res.data.token}
                        });
                        this.ruleForm = {}
                        this.$router.push({
                            path: '/',
                            query: {
                                tid:this.$router.currentRoute.query.sid?this.$router.currentRoute.query.sid:'',
                                sid:this.$router.currentRoute.query.tid?this.$router.currentRoute.query.tid:''
                            }
                        });
                    } catch (error) {
                        // console.log('error');
                    }     
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner{
    border:none !important;
    background-color: rgba(248, 249, 251, 1) !important;
}
::v-deep .el-form-item{
    margin-bottom:0;
}
::v-deep .el-button,.el-button:hover{
    background-color: #A21B26;
    color:#FFFFFF;
    border:none;
    border-radius: 1.875rem;
    width:80%;
    margin-top:1.5rem;
    font-size:18px;
}
::v-deep .el-form--label-top .el-form-item__label{
    float:left;
    padding:0;
}
.login-container{
    background: url("@/assets/left.png")no-repeat top / 100% 100%;
    height:100vh;
    box-sizing: border-box;
}
.arrow{
    text-align: left;
    padding:2.5%;
    img{
        width: 1.25rem;
        margin-top:1.25rem;
    }
}
.login-box{
    padding:1.25rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    width:95%;
    margin:8rem 0 2.5% 2.5%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 11px  rgba(212, 48, 48, 0.15);
    p{
        font-size:12px;
        color:rgba(153, 153, 153, 1);
        margin-top:8px;
        text-align: center;
    }
    .Toggle,p>span{
        color:#A21B26;
    }
}
::v-deep .el-message{
    min-width: 280px !important;
}
</style>