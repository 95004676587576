<template>
    <div class="dialogBox">
      <el-dialog
       :title="title"
        :visible.sync="dialogVisible"
        :width="width"
        :show-close="false"
        :before-close ="handleClose"
      >
      <div>
        <el-form>
            <el-form-item>
                <el-input v-model="rule" clearable show-word-limit :readonly="true">
				</el-input>
                <el-row :gutter="10">
                    <el-col v-for="item in keys" :key="item.id" :span="8">
                        <div @click="keyPress(item)" v-if="item.id < 11" class="tel">{{ item.label }}</div>
                        <div @click="Press(item)" v-if="item.id == 11" class="tel">
                            <img src="@/assets/1.png" alt="">
                        </div>
                        <div @click="verify(item)" v-if="item.id == 12" class="tel-bg">
                            {{ item.label }}
                        </div>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
      </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: 'dialogBox',
    props: {
        dialogVisible:{
            type:Boolean,
            default:false
        },
        rule:{
            type:String,
            default:''
        },
        width:{
            type:String,
            default:'30%'
        }
    },
    data(){
        return{
            title:"食事人数を入力してください",
            keys: [
                {id: 1, label: "1"},
                {id: 2, label: "2"},
                {id: 3, label: "3"},
                {id: 4, label: "4"},
                {id: 5, label: "5"},
                {id: 6, label: "6"},
                {id: 7, label: "7"},
                {id: 8, label: "8"},
                {id: 9, label: "9"},
                {id: 10, label: "0"},
                {id: 11, label: "x"},
                {id: 12, label: "確認"},
            ]
        }
    },
    methods: {
        handleClose(){
            this.$emit("close")
        },
        verify(){
            this.$emit("verify",this.rule)
        },
        keyPress(key) {
            this.rule = this.rule + key.label;
        },
        Press(){
            if (this.rule.length > 0) {
                this.rule = this.rule.slice(0, -1)
            }
        }             
    },
  }
  </script>
  
  <style scoped lang="less">
    .tel{
        border-radius: 4px;
        background: #F5F5F5;
        color:#333333;
        margin-top:10px;
        img{
            width:25px;
            vertical-align: middle;
        }
    }
    .tel-bg{
        margin-top:10px;
        border-radius: 4px;
        background: #A21B26;
        color:#FFFFFF;
    }
    table{
        width: 100%;  
    }
    ::v-deep .el-dialog__title{
        color:#A21B26 !important;
        font-size:1.25rem !important;
        text-align: left !important;
        font-weight: 500;
    }
    ::v-deep .el-dialog__header{
        text-align: left !important;
    }
    ::v-deep .el-dialog__body{
        padding-top:0 !important;
    }
    ::v-deep .el-input__inner{
        border-radius: 4px;
        background: #F5F5F5;
        border:none;
    }
    ::v-deep .el-form-item{
        text-align: center;
    }
    ::v-deep .el-form-item__content{
        line-height:40px;
    }
  </style>
  