import Vue from 'vue'; 
import Vuex from 'vuex';
import { SessionUtils } from '@/utils/session.js'
Vue.use(Vuex)
const actions = {}
const mutations = {
    setUserInfo: (state, payload) => {
        state.userInfo.avatar = payload.userInfo.avatar;
        state.userInfo.token = payload.userInfo.token;
        SessionUtils.set('userInfo', state.userInfo)
    }
}
const state = {
    userInfo:SessionUtils.get('userInfo') || {},
}
const getters = {
    getUserInfo: (state) => {
        return state.userInfo;
    }
}

const store = new Vuex.Store({
    actions: actions,
    mutations,
    state,
    getters
})
export default store