import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/loginView.vue'
import LoginpadView from '@/views/loginpadView.vue'
import PersonalViews from '@/views/PersonalViews.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:"首页"
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta:{
      title:"登录"
    },
  },
  {
    path: '/personal',
    name: 'personal',
    component: PersonalViews,
    meta:{
      title:"个人中心"
    },
  },
  {
    path: '/loginpad',
    name: 'loginpad',
    component: LoginpadView,
    meta:{
      title:"登录"
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.afterEach(() => {
  document.title = '註文します';
})

export default router
