<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>
<script>
  export default {
    name: 'App',
    data(){
      return{
      }
    },
    created() {
    },
    mounted() {
    }
  }                    
</script>
<style lang="less">
*{
  margin:0;
  padding:0;
}
//滚动条样式设置
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #A21B26;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
.confirmButton{
  background-color: #A21B26 !important;
  border-color: #A21B26 !important;
}
::v-deep .el-input__inner::placeholder{
    color:#999999!important;
}
@media screen and (max-width: 500px) {
    .el-message {
      min-width: 300px !important;
    }
    .el-message-box{
      max-width: 300px !important;
    }
}
h4{
  font-size:1.25rem;
  color:#333333;
  text-align: left;
}
body {
    font-family: 'Source Han Sans', 'Noto Sans CJK SC', 'HanHei SC', 'Microsoft YaHei', sans-serif !important;
}
ul {
    list-style-type: none;
    padding: 0;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
