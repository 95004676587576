<template>
    <div class="detailDialog">
      <el-dialog
        :visible.sync="dialogVisible"
        :width="width"
        :show-close="false"
        :before-close ="handleClose"
      >
      <slot>
      </slot> 
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: 'detailDialog',
    props: {
        dialogVisible:{
            type:Boolean,
            default:false
        },
        width:{
            type:String,
            default:'30%'
        }
    },
    data(){
        return{
        }
    },
    methods: {
        handleClose(){
            this.$emit("close")
        }  
    },
  }
  </script>
  
  <style scoped lang="less">
      ::v-deep .el-dialog__header{
            display:none;
      }
      ::v-deep .el-dialog__body{
        padding:0;
      }
      ::v-deep .el-dialog{
        border-radius: 0.625rem;
      }
  </style>
  