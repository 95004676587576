import request from '@/utils/request'

//获取首页信息
export function getIndexInfo(data) {
    return request({
      url: '/app/app/indexInfo',
      method: 'get',
      params: data
    })
}
//获取菜品列表
export function getDishList(data) {
    return request({
        url:'/app/app/disheList',
        method:'get',
        params:data
    })
}
//下单
export function order(data) {
    return request({
        url:'/app/app/orderCreat',
        method:'post',
        data:data
    })
}
//加餐
export function addMeal(data) {
    return request({
        url:'/app/app/orderAddDishe',
        method:'post',
        data:data
    })
}

//呼叫、结账
export function callServer(data) {
    return request({
        url:'/app/app/callServer',
        method:'get',
        params:data
    })
}

//获取本餐位订餐数据
export function orderInfo(data) {
    return request({
        url:'/app/app/orderInfo',
        method:'get',
        params:data
    })
}
//账号注册
export function register(data) {
    return request({
        url:'/app/sign/register',
        method:'post',
        data:data
    })
}
//用户登录
export function login(data) {
    return request({
        url:'/app/sign/login',
        method:'post',
        data:data
    })
}
//获取个人数据
export function getUserInfo(data) {
    return request({
        url:'/app/user/userInfo',
        method:'get',
        params:data
    })
}
//用户修改头像
export function uploadAvatar(data) {
    return request({
        url:'/app/user/uploadAvatar',
        method:'post',
        data:data
    })
}
//修改个人数据
export function userInfo(data) {
    return request({
        url:'/app/user/userInfo',
        method:'post',
        data:data
    })
}

//用户登录
export function loginpad(data) {
    return request({
        url:'/store/sign/login',
        method:'post',
        data:data
    })
}

//获取二维码
export function getQrcode(data) {
    return request({
        url:'/app/app/creatQrcode',
        method:'get',
        params:data
    })
}
