const baseMap = {
    base:' https://api.beginpos.com',
    // base:'http://192.168.0.22',
    prfix:'/api'
}
const baseUrl = baseMap.base+baseMap.prfix

module.exports = {
    baseUrl,
    baseMap
}