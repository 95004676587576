<template>
    <div class="sidebar" >
      <img :src="shop.store?shop.store.logo:''" alt="">
      <p>{{ shop.store?shop.store.title:'' }}</p>
      <ul>
        <li v-for="(item,index) in shop.menulv1" :key="index" :class="active == index?'active':''" @click="childClick(item.id,index)">
            {{item.title?item.title:''}}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { convertTimeToDateTime } from '@/utils/tools.js'
  export default {
    name: 'sidebarList',
    props: {
        shop: {
            type: Object,
            default(){
                return {}
            }  
        },
        active:{
            type:Number,
            default(){
                return -1
            }
        }
    },
    data(){
        return{
        }
    },
    mounted(){
    },
    methods: {
        childClick(item,index) {
            if(this.active == -1){
                this.$message.error('当面は食事の時間外です')
            }else{
                let time1 = new Date();
                time1.setFullYear(1970, 0, 1);
                const record = this.shop.menulv1[index];
                let endtime = convertTimeToDateTime(record.endtime?record.endtime:'23:59:59');
                let starttime = convertTimeToDateTime(record.starttime?record.starttime:'00:00:00');
                if (time1 >= starttime && time1 <= endtime){
                    this.$emit('childClick', item,index,0)
                }else{
                    this.$message.error('当面は食事の時間外です')
                }    
            }    
        }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
  .sidebar{
    width: 8.75rem;
    height:100vh;
    position:fixed;
    left:0;
    top:0;
    z-index:9;
    background: #A21B26;
    text-align: center;
    box-sizing: border-box;
    img{
        width: 40px;
        border-radius: 50%;
        margin-top:10px;
    }
    p{
        color:#FFFFFF;
        font-size:0.625rem;
        line-height:0.9375rem;
    }
    ul{
        margin-top:8px;
        color:#FFFFFF;
        font-size:1rem;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        li{
            margin-left:6%;
            width:95%;
            padding:10px;
            margin-bottom:15px;
            box-sizing: border-box;
            // white-space: nowrap;
        }
        .active{
            background:#FFFFFF url(@/assets/bc.png) no-repeat;
            font-size:1rem;
            color:#A21B26;
            border-radius: 20px 0 0  20px;
        }
    }
  }
  @media screen and (max-width: 767px){
    .sidebar{
        background-color: #FFFFFF;
        width:100%;
        height:46px;
        top:5rem;
        p,img{
            display:none;
        }
        ul{
            display:flex;
            color:#333333;
            font-size:1.25rem;
            margin-top:0;
            li{
                margin-bottom:0;
                white-space:nowrap;
            }
            .active{
                border-radius: 0;
                font-size:1.25rem;
                border-bottom:2px solid #A21B26;
            }
        }
    }
  }
  </style>
  